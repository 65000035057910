.footer {
    color: #ff5024;
    text-align: center;

    .foot-header {
        font-size: 28px;
        background: #ffe5de;
        width: fit-content;
        padding: 2px 5%;
        border-top-right-radius: 50px;

        p {
            font-weight: bolder;
        }
    }

    .support {
        background: #ffe5de;
        padding: 40px 20px;
        border-top-right-radius: 50px;

        .logos {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            gap: 15px;

            -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
            opacity: 0.8;
        }
    }
}

.footer .logos img {
    padding: 20px;
    height: 80px; /* O el tamaño que prefieras, manteniendo los logos proporcionales */
}

.footer .cta {
    color: white;
    padding: 40px 20px;
    background-color: #ff5024;
    font-weight: bold;
    font-size: 28px;

    a {
        color: #052A1A;
        text-decoration: underline solid #052A1A 2px;
        border-radius: 5px;
        display: inline-block;
        transition: background-color 0.3s ease
    }
}

@media (max-width: 768px) {
    .footer {

        .foot-header {
            font-size: 28px;
            background: #ffe5de;
            width: fit-content;
            padding: 2px 5%;
            border-top-right-radius: 50px;

            p {
                font-weight: bolder;
            }
        }

        .support {
            border-top-right-radius: 0;
        }
    }

    .footer .logos img {
        padding: 20px;
        height: 60px; /* O el tamaño que prefieras, manteniendo los logos proporcionales */
    }

    .footer .cta {
        color: white;
        padding: 40px 20px;
        background-color: #ff5024;
        font-weight: bold;
        font-size: 28px;

        a {
            color: #052A1A;
            text-decoration: underline solid #052A1A 2px;
            border-radius: 5px;
            display: inline-block;
            transition: background-color 0.3s ease
        }
    }
}