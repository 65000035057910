/* project.css */
.project {
    padding: 80px;
    background-color: #fff; /* O el color que prefieras para el fondo */

    h2 {
        font-size: 50px;
        line-height: 1.4;
        font-family: "Dela Gothic One", sans-serif;
        font-weight: 400;
        font-style: normal;
        margin: 0;
        color: #052A1A;
    }

    p {
        font-size: 2.5rem;
        line-height: 1.8;
        font-style: normal;
        font-weight: lighter;
        color: #052A1A;

        b {
            font-weight: bold;
        }

        span {
            color: #FF5722;
        }
    }
}


@media (max-width: 768px) {
    .project {
        padding: 10px;
        background-color: #fff; /* O el color que prefieras para el fondo */

        h2 {
            font-size: 2rem;
            text-align: start;
        }

        p {
            font-size: 1.8rem;
        }
    }

}