/* technology.css */
.technology {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 50px;
    background-color: #ffffff;

    .text-container {
        flex: 2;
        align-self: center;

        h2 {
            font-size: 50px;
            line-height: 1.4;
            font-family: "Dela Gothic One", sans-serif;
            font-weight: 400;
            font-style: normal;
            margin: 0;
            color: #052A1A;
        }

        p {
            font-size: 40px;
            line-height: 1.8;
            font-style: normal;
            font-weight: lighter;
            color: #052A1A;
        }
    }

    .image-container {
        flex: 1;
        display: flex;
        justify-content: flex-end;

        img {
            max-width: 100%;
            height: auto;
        }
    }
}

@media (max-width: 1200px) {
    .technology {
        flex-direction: column;
        align-items: flex-start;
        padding: 0 10px;
        margin-left: 10px;

        .image-container {
            display: flex;
            justify-content: center;
            padding-right: 0;
            width: 100%;
        }

        .image-container img {
            max-width: 70%;
        }
    }
}


@media (max-width: 768px) {
    .technology {
        flex-direction: column;
        align-items: flex-start;
        padding: 0 10px;
        margin-left: 10px;

        .text-container {
            align-self: center;
            padding-right: 0;
            text-align: center;

            p {
                font-size: 2rem;
                text-align: start;
            }

            h2 {
                font-size: 2.5rem;
                text-align: start;
            }
        }

        .image-container {
            display: flex;
            justify-content: center;
            padding-right: 0;
        }

        .image-container img {
            max-width: 80%;
        }
    }
}