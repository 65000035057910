.header {
    background-size: cover;
    background-position: center;
    color: white;
    padding-top: 100px;
    padding-left: 100px;
    padding-right: 100px;
    position: relative;
    height: 100vh;
    width: 100vw;
    overflow-x: hidden;

    .content {
        display: flex;
        flex-direction: column;
        height: 80%;

        .logo-container {
            display: flex;
            flex-direction: row;
            flex: 0 0 50%;
            align-items: flex-end;

            .logo {
                max-height: 157px;
            }
        }

        .text-container {
            display: flex;
            flex-direction: column;
            flex: 0 0 50%;
            max-width: 40%;
            justify-content: flex-end;

            h1 {
                font-size: 53px;
                margin: 0;
                color: #052A1A;
                font-weight: lighter;
            }

            p {
                font-size: 24px;
                color: #052A1A;
                padding: 0;
                margin-top: 50px;
            }

        }
    }
}

@media (max-width: 1440px) {
    .header {
        color: white;
        padding: 0;
        overflow-x: hidden;
        width: 100vw;

        .content {
            padding: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-content: center;

            .logo-container {
                display: flex;
                flex-direction: row;
                flex: 0 0 25%;
                align-items: flex-start;

                .logo {
                    max-height: 2.5rem;
                }
            }

            .text-container {
                display: flex;
                flex-direction: column;
                flex: 0 0 50%;
                max-width: 40%;
                justify-content: flex-end;

                h1 {
                    font-size: 53px;
                    text-align: start;
                }

                p {
                    font-size: 24px;
                    color: #052A1A;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .header {
        .content {
            justify-content: flex-start;

            .text-container {
                h1 {
                    font-size: 35px;
                    text-align: start;
                    font-weight: 400;
                }

                p {
                    font-size: 20px;
                }
            }
        }
    }
}
