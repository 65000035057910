.features {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background: #fff;
    padding: 0 50px;
    flex-wrap: wrap;

    h2 {
        font-size: 2.8rem;
        line-height: 1.4;
        font-family: "Dela Gothic One", sans-serif;
        font-weight: 400;
        font-style: normal;
        margin: 0;
        color: #052A1A;
    }

    .feature-grid {
        display: flex !important;
        flex-direction: row;
        flex-wrap: wrap;

        .feature:nth-of-type(odd) {
            background-color: #327C59;
        }

        .feature:nth-of-type(even) {
            background-color: #175137;
        }


        .feature {
            flex-basis: calc(33% - 40px);
            margin: 20px;
            color: white;
            border-radius: 10px;
            overflow: hidden;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            transition: transform 0.3s ease;
            height: 580px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-content: center;
            min-width: 400px;
            align-self: center;

            .icon {
                padding: 40px 40px 10px 40px;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                img {
                    width: 100px;
                    height: 83px;
                }
            }

            .content {
                padding: 10px 40px 60px 40px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-content: center;

                h3 {
                    font-size: 30px;
                    line-height: 1.4;
                    text-transform: uppercase;
                    max-width: 90%;
                    white-space: pre-wrap;
                    color: #7EC19F;
                    font-family: "Dela Gothic One", sans-serif;
                    font-weight: 400;
                    font-style: normal;

                    span {
                        color: #FFF;
                    }
                }

                p {
                    margin: 0;
                    font-size: 25px;
                    line-height: 1.2;
                    font-style: normal;
                    font-weight: lighter;
                }
            }

        }

        .feature:hover {
            transform: translateY(-5px);
        }
    }
}


@media (max-width: 1100px) {
    .features {
        padding: 0;

        h2 {
            font-size: 1.6rem;
            text-align: start;
            margin-left: 5px;
        }

        .feature-grid {
            display: flex;
            justify-content: center;

            .feature {
                min-width: 350px;
                align-self: center;

                .content {
                    h3 {
                        font-size: 1.6rem;
                    }
                    p {
                        font-size: 1.5rem;
                    }
                }
            }
        }
    }
}