/* about.css */
.about {
    display: flex;
    background-color: #FF5722;
    color: white;
    height: 100vh;
    width: 100vw;
    flex-direction: row;
    padding: 25px 25px 25px 25px;

    .papatronica-image-container {
        flex: 0 0 30%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img {
            max-width: 80%;
            height: 80%;
            border-radius: 280px;
        }
    }

    .info-container {
        display: flex;
        flex-direction: column;
        padding-left: 50px;

        .icons-container {
            display: flex;
            flex: 0 0 30%;
            flex-direction: row;
            align-items: center;

            img {
                max-height: 85px;
            }
        }

        .text-container {
            display: flex;
            flex-direction: row;
            flex: 0 0 60%;

            h2 {
                font-size: 50px;
                line-height: 1.4;
                text-transform: uppercase;
                max-width: 90%;
                white-space: pre-wrap;
                font-family: "Dela Gothic One", sans-serif;
                font-weight: 400;
                font-style: normal;

                span {
                    color: #000;
                }
            }
        }

    }
}

@media (max-width: 1440px) {
    .about {
        padding: 0;

        .papatronica-image-container {
            display: none;
        }

        .info-container {
            padding: 0;

            .icons-container {
                display: flex;
                flex-direction: row;
                justify-content: center;

                img {
                    max-width: 80%;
                }
            }

            .text-container {
                flex-direction: row;
                justify-content: center;

                h2 {
                    text-align: center;
                    font-size: 40px;
                }
            }
        }
    }
}

@media (max-width: 1024px) {
    .about {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 0;

        .info-container {
            .icons-container {
                img {
                    max-width: 60%;
                }
            }

            .text-container {
                h2 {
                    text-align: center;
                    font-size: 35px;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .about {
        .info-container {
            .text-container {
                h2 {
                    text-align: center;
                    font-size: 30px;
                }
            }

            .icons-container {
                display: none;

                img {
                    display: none;
                }
            }
        }
    }
}