.usage-manual {
    position: relative;
    width: 100%;


    .manual-header {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-top: -150px; /*por ahora*/

        .header-container {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            background-color: #FF5722;
            border-radius: 50px 50px 0 0;
            margin-bottom: -40px;
            padding: 30px 70px 0 0;
            text-align: end;

            h2 {
                font-size: 50px;
                line-height: 1.4;
                font-family: "Dela Gothic One", sans-serif;
                font-weight: 400;
                font-style: normal;
                margin: 0 0 -60px 0;
                color: #FCDFD0;
                max-width: 50%;
            }
        }
    }

    .steps {
        background-color: #FF5722;
        color: white;
        border-radius: 50px;
        padding: 50px;

        display: flex;
        justify-content: space-around;

        .step {
            max-width: 458px;

            img {
                width: 112px;
                height: 93px;
                margin-bottom: 10px;
            }

            h3 {
                font-size: 28px;
                margin-bottom: 10px;
                line-height: 1.4;
                font-family: "Dela Gothic One", sans-serif;
                font-weight: 400;
                font-style: normal;
            }

            p {
                font-size: 28px;
            }
        }

    }

    .manual-download {
        background-color: white;
        color: #FF5722;
        transition: background-color 0.3s;
        text-decoration: none;
        font-weight: bold;
        position: absolute;
        bottom: -45px;
        right: 80px;

        width: fit-content;
        max-width: 250px;
        border-radius: 100px;
        padding: 10px 25px;
        display: flex;
        gap: 12px;
        justify-content: center;
        align-items: center;
        border: 2px solid #FF5722;

        img {
            height: 50px;
        }
    }

    .manual-download:hover {
        background-color: #E64A19;
        color: white;
    }
}

@media (max-width: 768px) {
    .usage-manual {
        position: relative;
        width: 100%;


        .manual-header {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            margin-top: -150px; /*por ahora*/

            .header-container {
                display: flex;
                flex-direction: row;
                justify-content: center;
                background-color: #FF5722;
                margin-bottom: -40px;
                padding: 30px 0;
                width: 100%;

                h2 {
                    font-size: 2rem;
                    line-height: 1.4;
                    font-family: "Dela Gothic One", sans-serif;
                    font-weight: 400;
                    font-style: normal;
                    margin: 0;
                    color: #FCDFD0;
                    max-width: 50%;
                    text-align: center;
                }
            }
        }

        .steps {
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            .step {

                img {
                    width: 100px;
                    height: 80px;
                }
            }

        }
    }
}