.papatronica-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5rem;

    .figure-container {
        position: relative;
        height: 600px;
        width: 800px;

        img {
            width: 500px;
            position: absolute;
            z-index: 2;
            top:0;
        }

        .figure {
            height: 100%;
            width: 100%;
            background-color: #FCDFD0;
            border-top-right-radius: 300px;
            border-bottom-right-radius: 300px;
        }
    }

    .papatronica-info {
        padding: 30px;
    }

    p {
        font-size: 40px;
        line-height: 1.8;
        font-style: normal;
        font-weight: lighter;
        color: #052A1A;
    }

    b {
        font-weight: bold;
    }

}

@media (max-width: 1500px) {
    .papatronica-container {
        gap: 25px;
        margin-top: 2rem;

        .figure-container {
            display: none;
        }

        .papatronica-info {
            padding: 0 10px;
            margin-left: 10px;
        }

    }
}

@media (max-width: 768px) {
    .papatronica-container {

        p {
            font-size: 2rem;
        }

    }
}