/* specifications.css */
.specifications {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 50px;

    h2 {
        font-size: 50px;
        line-height: 1.4;
        font-family: "Dela Gothic One", sans-serif;
        font-weight: 400;
        font-style: normal;
        margin-bottom: 50px;
        color: #052A1A;
    }

    .specs-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-evenly;
        padding: 50px 50px 150px 50px;
        background: #FCDFD0;
        border-radius: 50px;

        > div {
            flex: 0 0 33%;
        }

        .specification {
            margin-bottom: 20px;
            color: #FF5024;
            max-width: 458px;

            p {
                margin-top: 0;
                white-space: pre-line;
                font-size: 28px;
                line-height: 1.2;
                font-style: normal;
                font-weight: lighter;
            }

            h4 {
                font-size: 28px;
                margin: 0;
                line-height: 1.2;
                font-style: normal;
                font-weight: 800;
            }
        }
    }
}

@media (max-width: 768px) {
    .specifications {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        margin-bottom: 45px;

        h2 {
            font-size: 1.6rem;
            text-align: start;
        }

        .specs-container {
            display: flex;
            flex-direction: column;
            width: 90%;

        }
    }
}
